<template>
  <div class="access-point-form">
    <vx-card class="mb-base no-shadow">
      <div class="vx-col w-1/4 mb-6">
        <label class="vs-input--label w-full mb-2">{{ $t("shops.select_system") }}</label>
        <v-select
          name="system"
          :placeholder="$t('shops.select_a_system')"
          class="select-large"
          :value="systemSelectValue"
          :options="systemSelectOptions.map(option => ({ label: option.label, value: option.value}))"
          @input="option => selectSystem(option)"
        />
      </div>

      <div class="mb-6" v-if="systemSelectValue === 'Axess'">
        <div class="vx-row w-full">
          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.url") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="axess.values.url" name="axessUrl" :placeholder="$t('shops.URL')" />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.user_name") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="axess.values.userName" name="axessUser" :placeholder="$t('shops.user')" />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.soap_user_name") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="axess.values.soapUserName"
              name="axessSoapUser"
              :placeholder="$t('shops.soap_user')"
            />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.country_code") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="axess.values.countryCode"
              name="axessCountryCode"
              :placeholder="$t('shops.country_code')"
            />
          </div>
        </div>

        <div class="vx-row w-full">
          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.login_id") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="axess.values.loginId" name="axessLoginId" :placeholder="$t('shops.login_ID')" />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.password") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="axess.values.password"
              name="axessPassword"
              type="password"
              :placeholder="$t('shops.password')"
            />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.soap_password") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="axess.values.soapPassword"
              name="axessSoapPassword"
              type="password"
              :placeholder="$t('shops.soap_password')"
            />
          </div>

          <div class="vx-col w-1/4">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.proj_no") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="axess.values.projNo" name="axessProjNo" :placeholder="$t('shops.ProjNo')" />
          </div>
        </div>
      </div>

      <div class="mb-6" v-if="systemSelectValue === 'Skidata'">
        <div class="vx-row w-full">
          <div class="vx-col w-1/2">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.url") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="skidata.values.url" name="skidataUrl" :placeholder="$t('shops.URL')" />
          </div>

          <div class="vx-col w-1/2">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.user_name") }}</label>
            <vs-input class="w-full mr-2 mb-2" v-model="skidata.values.userName" name="skidataUser" :placeholder="$t('shops.user')" />
          </div>
        </div>

        <div class="vx-row w-full">
          <div class="vx-col w-1/2">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.client_name") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="skidata.values.clientName"
              name="skidataClientName"
              :placeholder="$t('shops.client_name')"
            />
          </div>

          <div class="vx-col w-1/2">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.password") }}</label>
            <vs-input
              class="w-full mr-2 mb-2"
              v-model="skidata.values.password"
              name="skidataPassword"
              :placeholder="$t('shops.password')"
              type="password"
            />
          </div>
        </div>

        <div class="vx-row w-full">
          <div class="vx-col w-full">
            <label class="vs-input--label w-full mb-2">{{ $t("shops.software") }}</label>
            <v-select
              name="skidataSoftware"
              v-model="skidata.values.software"
              :placeholder="$t('shops.select_software')"
              class="w-1/4 select-large"
              :value="skidataSoftwareValue"
              :options="skidataSoftwareOptions.map(option => ({ label: option.label, value: option.value }))"
              @input="option => selectSoftware(option)"
            />
          </div>
        </div>
      </div>

      <div class="mb-6">
        <vs-button @click="onSaveClicked()" type="filled">{{ $t("shops.save") }}</vs-button>
        <span v-if="systemSelectValue === 'Axess' || systemSelectValue === 'Skidata'">
          <vs-button v-if="!showTables" @click="onShowCatalogsClicked()" class="ml-2" type="filled">{{ $t("Show Catalogs") }}</vs-button>
          <vs-button v-else @click="onShowCatalogsClicked()" class="ml-2" type="filled">{{ $t("Hide Catalogs") }}</vs-button>
        </span>
      </div>

      <p class="error-message">{{errorMessage}}</p>

      <div class="mb-6" v-if="showTables">
        <div class="w-full scrollx" v-if="systemSelectValue === 'Axess'">
          <div class="vx-col mt-5" v-if="resortCatalog.participants !== undefined ">
            <h2>{{ $t("shops.participants.0") }}</h2>
            <data-table :data="resortCatalog.participants" :options="tableOptions" />
          </div>

          <div class="vx-col mt-5" v-if="resortCatalog.pools !== undefined">
            <h2>{{ $t("shops.pools") }}</h2>
            <data-table :data="resortCatalog.pools" :options="tableOptions" />
          </div>

          <div class="vx-col mt-5" v-if="resortCatalog.products !== undefined">
            <h2>{{ $t("shops.products.0") }}</h2>
            <data-table :data="resortCatalog.products" :options="tableOptions" />
          </div>

          <div class="vx-col mt-5" v-if="resortCatalog.extras !== undefined">
            <h2>{{ $t("shops.extras") }}</h2>
            <data-table :data="resortCatalog.extras" :options="tableOptions" />
          </div>
        </div>

        <div class="w-full scrollx" v-if="systemSelectValue === 'Skidata'">
          <div class="w-auto m-4" v-for="catalog in resortCatalog.catalogs" :key="catalog.CatalogId">
            <vx-card>
              <div class="vx-row">
                <h2 class="mr-2">{{ $t("shops.name") }}: </h2>
                <h2>{{ catalog.Name }}</h2>
              </div>

              <div class="vx-row">
                <h3 class="mr-2">{{ $t("shops.shortName") }}: </h3>
                <p class="mt-1">{{ catalog.ShortName }}</p>
              </div>

              <div class="vx-row">
                <h3 class="mr-2">{{ $t("shops.catalogID") }}: </h3>
                <p class="mt-1">{{ catalog.CatalogId }}</p>
              </div>

              <div class="vx-row">
                <h3 class="mr-2">{{ $t("shops.contractorID") }}: </h3>
                <p class="mt-1">{{ catalog.ContractorId }}</p>
              </div>

              <div class="vx-row w-full mr-32" v-if="catalog.products !== undefined">
                <h2>{{ $t("shops.products") }}</h2>
                <data-table :data="catalog.products" :options="tableOptions" />
              </div>

              <div class="vx-row w-full mr-32" v-if="catalog.participants !== undefined">
                <h2>{{ $t("shops.participants") }}</h2>
                <data-table :data="catalog.participants" :options="tableOptions" />
              </div>
            </vx-card>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";

const axessDefault = () => ({
  url: "",
  loginId: "",
  userName: "",
  password: "",
  soapUserName: "",
  soapPassword: "",
  countryCode: "",
  projNo: "",
  system: "",
});

const skidataDefault = () => ({
  url: "",
  clientName: "",
  userName: "",
  password: "",
  software: "",
  system: "",
});

export default {
  name: "AccessPointForm",

  mixins: [notifications],

  components: {
    DataTable,
  },

  data() {
    return {
      systemSelectOptions: [
        { label: "Axess", value: "Axess" },
        { label: "Skidata", value: "Skidata" },
        { label: "None", value: "None" },
      ],
      systemSelectValue: "None",
      axess: {
        id: 0,
        values: axessDefault(),
      },
      skidata: {
        id: 0,
        values: skidataDefault(),
      },
      skidataSoftwareOptions: [
        { label: "Flexible", value: "flexible" },
        { label: "Freemotion", value: "freemotion" },
      ],
      skidataSoftwareValue: "",
      resortData: {},
      showTables: false,
      errorMessage: ""
    };
  },

  computed: {
    ...mapGetters("shops/resorts", {
      resortConfig: "resortConfig",
      resortCatalog: "resortCatalog",
    }),

    tableOptions() {
      return {
        actions: false,
        filters: false,
        pagination: false,
      };
    },
  },

  methods: {
    ...mapActions("shops/resorts", {
      loadResortConfig: "loadResortConfig",
      setResortConfig: "setResortConfig",
      loadResortCatalog: "loadResortCatalog",
    }),

    selectSystem(option) {
      if (option === null) {
        this.systemSelectValue = ""
      } else {
        this.systemSelectValue = option.value;
      }
    },

    selectSoftware(option) {
      this.skidataSoftwareValue = option.value;
      this.skidata.values.software = this.skidataSoftwareValue;
    },

    async onSaveClicked() {
      this.resortData.system = this.systemSelectValue;
      if (this.resortData.system === "Axess") {
        try {
          this.axess.values.system = this.resortData.system.toLowerCase();
          this.axess.id = this.$route.params.uuid;
          this.setResortConfig(this.axess);

          this.notifySuccess(
            i18n.t("shops.updated"),
            i18n.t("shops.the_resort_has_been_updated_successfully")
          );
        } catch (e) {
          console.warn(e);
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_update_resort"));
        }
      } else if (this.resortData.system === "Skidata") {
        try {
          this.skidata.id = this.$route.params.uuid;
          this.skidata.values.system = this.resortData.system.toLowerCase();
          this.setResortConfig(this.skidata);
          this.notifySuccess(
            i18n.t("shops.pending_to_pay"),
            i18n.t("shops.the_resort_has_been_updated_successfully")
          );
        } catch (e) {
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_resort"));
          console.warn(e);
        }
      } else {
        this.skidata.id = this.$route.params.uuid;
        this.axess.id = this.$route.params.uuid;
        this.setResortConfig(this.axess);
        this.resetData();
      }
    },

    async onShowCatalogsClicked() {
      this.showTables = !this.showTables;
      if (this.showTables) {
        this.$vs.loading();
        try {
          this.errorMessage = "";
          await this.loadResortCatalog(this.$route.params.uuid);
        } catch (e) {
          this.notifyError(i18n.t("shops.error"), e);
          this.showTables = !this.showTables;
          this.errorMessage = e;
          console.warn(e);
          this.$vs.loading.close();
        } finally {
          this.$vs.loading.close();
        }
      }
    },

    resetData() {
      this.axess.values.url = "";
      this.axess.values.loginId = "";
      this.axess.values.userName = "";
      this.axess.values.password = "";
      this.axess.values.soapUserName = "";
      this.axess.values.soapPassword = "";
      this.axess.values.countryCode = "";
      this.axess.values.projNo = "";
      this.axess.values.system = "";

      this.skidata.values.url = "";
      this.skidata.values.clientName = "";
      this.skidata.values.userName = "";
      this.skidata.values.password = "";
      this.skidata.values.software = "";
      this.skidata.values.system = "";
    }
  },

  created() {
    this.$vs.loading();
    this.resetData();

    this.resortData = this.resortConfig;

    this.loadResortConfig(this.$route.params.uuid)
      .then(() => {
        if (this.resortConfig.system === "axess") {
          this.systemSelectValue = "Axess";
          this.axess.values.url = this.resortConfig.url;
          this.axess.values.loginId = this.resortConfig.loginId;
          this.axess.values.userName = this.resortConfig.userName;
          this.axess.values.password = this.resortConfig.password;
          this.axess.values.soapUserName = this.resortConfig.soapUserName;
          this.axess.values.soapPassword = this.resortConfig.soapPassword;
          this.axess.values.countryCode = this.resortConfig.countryCode;
          this.axess.values.projNo = this.resortConfig.projNo;
          this.axess.values.system = this.resortConfig.system;
        } else if (this.resortConfig.system === "skidata") {
          this.systemSelectValue = "Skidata";
          this.skidata.values.url = this.resortConfig.url;
          this.skidata.values.clientName = this.resortConfig.clientName;
          this.skidata.values.userName = this.resortConfig.userName;
          this.skidata.values.password = this.resortConfig.password;
          this.skidata.values.software = this.resortConfig.software;
          this.skidata.values.system = this.resortConfig.system;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },
};
</script>

<style>
  .error-message {
    color:red;
  }
</style>
